@import "~/styles/theme.less";
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f0f2f5;
  background-image: url(./images/login_bg.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}

.card {
  width: 450px;
}

.logo {
  width: 160px;
  margin: 0 auto;
  margin-bottom: 40px;
}
